@font-face {
  font-family: 'Quicksand';
  src: url('./Fonts/Quicksand.ttf');
}

body {
  margin: 0;
  font-family: 'Quicksand', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  background: #f8f8f8;
  color: #626262;
}

.sombras {
  background: #ffffff;
  -webkit-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.75);
}

/* redes */
.redes {
  background: #00c400;
  width: 57px;
  height: 57px;
  position: fixed;
  right: 35px;
  padding: 10px;
  border-radius: 50%;
  z-index: 99999;
  bottom: 80px;
}

.redes_agendado {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: #00c400;
  padding: 0px 25px;
  border-radius: 15px;
  width: fit-content;
  transition: all ease-in-out 0.3s;
}
.redes_agendado img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.redes_agendado:hover {
  background: #00c400af;
  transition: all ease-in-out 0.3s;
}
/* fin - redes */

/* froms */
.input_form {
  background: #ffffff;
  background-color: #ffffff !important;
  border: solid 1px #d2d2d2 !important;
  padding: 0px 15px !important;
  width: calc(100% - 30px) !important;
  height: 60px !important;
  border-radius: 20px !important;
  margin-bottom: 20px !important;
}
.input_form:focus {
  border: solid 2px #797979 !important;
}

.input_form[type='email']:focus:valid {
  border: 2px solid #4eb5ea !important;
}
.input_form[type='tel']:focus:valid {
  border: 2px solid #4eb5ea !important;
}
.input_form[type='text']:focus:valid {
  border: 2px solid #4eb5ea !important;
}

.input_form[type='email']:focus {
  border: 2px solid #f44336 !important;
}
.input_form[type='tel']:focus:invalid {
  border: 2px solid #f44336 !important;
}
.input_form[type='text']:focus:invalid {
  border: 2px solid #f44336 !important;
}

.area_form {
  background: #ffffff;
  background-color: #ffffff !important;
  border: solid 1px #d2d2d2 !important;
  padding: 10px 15px !important;
  width: calc(100%) !important;
  height: 180px !important;
  border-radius: 20px !important;
}
.area_form:focus-visible {
  border: solid 2px #797979 !important;
  outline: none !important;
}
.area_form:focus:invalid {
  border: 2px solid #f44336 !important;
}
.area_form:focus:valid {
  border: 2px solid #4eb5ea !important;
}

.btn,
.btn-large,
.btn-small {
  border: solid 2px #626262;
  background-color: #ffffff00 !important;
  border-radius: 10px !important;
  font-weight: bold;
  font-size: 16px !important;
  color: #626262;
  text-transform: unset !important;
  box-shadow: none;
}

/* inicio - agenda horas */
.hora_div {
  width: 100px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #000000 !important;
  background-color: #81e293;
  font-weight: bold !important;
  transition: background-color linear 0.3s;
  cursor: pointer;
}
.hora_div:hover {
  background-color: #32b34a;
  transition: background-color linear 0.3s;
}
.hora_div::before {
  content: attr(title);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #363636;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

[type='radio']:checked + .hora_div:after {
  content: attr(title);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  margin: 0 !important;
  background-color: #132640 !important;
  height: 100%;
  width: 100%;
  -webkit-transition: 0.28s ease;
  transition: 0.28s ease;
  border-radius: 100px;
  transform: scale(1.02);
  cursor: pointer;
}

/* efecto */
.horizontal_horas {
  width: 100%;
  height: auto;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  display: flex;
  align-items: center;
  position: relative;
  gap: 30px;
}
.active {
  scroll-snap-type: none;
}
.horizontal_horas::-webkit-scrollbar {
  background: transparent;
  height: 10px;
}
.horizontal_horas::-webkit-scrollbar-thumb {
  background: #132640;
  border-radius: 15px;
}
.hora_select {
  scroll-snap-align: start;
}

/* fin - agenda horas */

/* fin - froms */

/* header */
nav {
  background-color: #f8f8f8;
  box-shadow: none;
  padding: 0 50px;
  height: 142px;
  display: flex;
  align-items: center;
}
.nav-wrapper {
  width: 100%;
  height: 102px !important;
}

.hide-on-med-and-down {
  height: 102px;
  display: flex;
  align-items: center;
}
.hide-on-med-and-down a {
  color: #363636;
  height: 42px;
  line-height: 42px;
  font-weight: 400;
  transition: all linear 0.3s;
}
.hide-on-med-and-down a:hover {
  color: #2baaec;
  background-color: #ffffff00;
  transition: all linear 0.3s;
  font-weight: 600;
}

.sidenav-trigger {
  background-color: transparent;
  cursor: pointer;
  border: none;
}
.sidenav-trigger:focus {
  background-color: transparent;
}

.burger {
  height: 102px;
}
/* fin - header */

/* footer */
footer {
  background-color: #132640 !important;
}

.footer_list {
  list-style: disc !important;
  padding-left: 20px !important;
  margin-top: 20px;
}

.footer_list li {
  list-style: disc !important;
  margin: 10px 0;
}

.center_footer {
  display: flex;
  justify-content: center;
}
/* fin - footer */

/* button */
.button_header {
  padding: 0px 30px;
  border: 2px solid #e2424e;
  background-color: #e2424e;
  color: #ffffff !important;
}
.button_header:hover {
  background-color: #ffffff00 !important;
  color: #e2424e !important;
}

.button_footer {
  background-color: #b4d9f5;
  color: #132640;
  font-size: 16px;
  border-radius: 20px;
  padding: 10px 20px;
  margin-top: 20px;
  position: absolute;
}

.button_1 {
  border: 2px solid #4eb5ea;
  background-color: #4eb5ea;
  font-weight: bold;
  padding: 10px 35px;
  border-radius: 10px;
  font-size: 18px;
  transition: all ease-in-out 0.3s;
}
.button_1:hover {
  background-color: #ffffff00;
  color: #4eb5ea;
  transition: all ease-in-out 0.3s;
}

.button_2_1 {
  color: #ffffff;
  border: none;
  cursor: pointer;
  border: 2px solid #132640;
  background-color: #132640;
  font-weight: bold;
  padding: 10px 35px;
  border-radius: 10px;
  font-size: 18px;
  transition: all ease-in-out 0.3s;
}
.button_2 {
  color: #ffffff;
  border: none;
  cursor: pointer;
  border: 2px solid #132640;
  background-color: #132640;
  font-weight: bold;
  padding: 10px 35px;
  border-radius: 10px;
  font-size: 18px;
  transition: all ease-in-out 0.3s;
}
.button_2:hover {
  color: #132640;
  background-color: #ffffff00;
  transition: all ease-in-out 0.3s;
}
.button_2:focus {
  background-color: #132640;
}

.button_3 {
  border: 2px solid #e2424e;
  background-color: #e2424e;
  font-weight: bold;
  padding: 10px 35px;
  border-radius: 10px;
  font-size: 18px;
  transition: all ease-in-out 0.3s;
}
.button_3:hover {
  background-color: #ffffff00;
  color: #e2424e;
  transition: all ease-in-out 0.3s;
}

.button_4 {
  color: #ffffff;
  border: none;
  cursor: pointer;
  border: 2px solid #e2424e;
  background-color: #e2424e;
  font-weight: bold;
  padding: 10px 35px;
  border-radius: 10px;
  font-size: 18px;
  transition: all ease-in-out 0.3s;
}
.button_4:hover {
  color: #e2424e;
  background-color: #ffffff00;
  transition: all ease-in-out 0.3s;
}
.button_4:focus {
  background-color: #e2424e;
}

.button_redes {
  border-radius: 50% !important;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button_redes img {
  width: 30px;
}
.redes_color_1 {
  background-color: #e2424e !important;
}

.button_redes_1 {
  border-radius: 50% !important;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button_redes_1 img {
  width: 25px;
  max-height: 25px;
  object-fit: contain;
}
.redes_color_2 {
  background-color: #3b5998 !important;
}
.redes_color_3 {
  background-color: #c13584 !important;
}
.redes_color_4 {
  background-color: #25d366 !important;
}
/* fin - button */

/* textos */
a {
  color: #ffffff;
  cursor: pointer;
}

.text_footer {
  font-size: 15px;
}

.linea::before {
  content: '';
  position: absolute;
  border-color: #b4d9f5;
  border-style: solid;
  border-width: 0 0.3em 0 0;
  height: 3em;
  margin-top: -1em;
  transform: rotate(90deg);
  width: 0.5em;
  margin-top: 4px;
  margin-left: 15px;
}

.linea_2::before {
  content: '';
  position: absolute;
  border-color: #b4d9f5;
  border-style: solid;
  border-width: 0 0.2em 0 0;
  height: 3em;
  margin-top: -1em;
  transform: rotate(90deg);
  width: 0.5em;
  margin-top: 4px;
  margin-left: 45px;
}

.linea_3 {
  display: flex;
  justify-content: center;
}
.linea_3::before {
  content: '';
  position: absolute;
  border-color: #132640;
  border-style: solid;
  border-width: 0 0.2em 0 0;
  height: 3em;
  margin-top: -1em;
  transform: rotate(90deg);
  width: 0.5em;
  margin-top: 4px;
}

.linea_4 {
  display: flex;
  justify-content: center;
}
.linea_4::before {
  content: '';
  position: absolute;
  border-color: #b4d9f5;
  border-style: solid;
  border-width: 0 0.2em 0 0;
  height: 3em;
  margin-top: -1em;
  transform: rotate(90deg);
  width: 0.5em;
  margin-top: 4px;
}

.linea_5 {
  display: flex;
  justify-content: center;
}
.linea_5::before {
  content: '';
  position: absolute;
  border-color: #4eb5ea;
  border-style: solid;
  border-width: 0 0.2em 0 0;
  height: 3em;
  margin-top: -1em;
  transform: rotate(90deg);
  width: 0.5em;
  margin-top: 4px;
}

.linea_6::before {
  content: '';
  position: absolute;
  border-color: #e2424e;
  border-style: solid;
  border-width: 0 0.2em 0 0;
  height: 3em;
  margin-top: -1em;
  transform: rotate(90deg);
  width: 0.5em;
  margin-top: 4px;
  margin-left: 45px;
}
/* fin - textos */

/* containers */
.contenedor {
  margin: 0 auto;
  width: 80%;
}

.contenedor_servicios_index {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 50px;
  /* gap: 70px 20px; */
}

.contenedor_servicios {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 70px 50px;
}

.contenedor_agenda {
  background: #ffffff;
  margin: 0 20px;
  padding: 5px 0 35px 0;
  border-radius: 15px;
}
/* fin - containers */
/* contacto */
.contenedor_contacto {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  border-radius: 15px;
  width: calc(35% - 17.5px);
  margin-right: 35px;
}

.mapa_contacto {
  width: calc(65% - 17.5px);
}
/* fin - contacto */

.margin_1 {
  margin: 0;
}

/* tarjetas servicios */
.servicios_index {
  width: 248px;
  font-weight: bold;
  text-align: center;
  background-color: #ffffff;
  color: #363636;
  border-radius: 0 0 25px 25px;
  -webkit-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.4);
}
.servicios_index div {
  padding: 20px 0px;
}

.servicios_index a {
  font-weight: bold;
  text-align: center;
  color: #64bab7;
  text-decoration-line: underline;
}
/* fin - tarjetas servicios */

.padding_1 {
  padding-bottom: 40px;
}

.brand-logo {
  height: 102px;
}

.servicios_shadow > img {
  position: relative;
  transition: all ease-in-out 0.3s;
}
.servicios_shadow {
  overflow: hidden;
  font-weight: bold;
  text-align: center;
  background-color: #ffffff;
  color: #363636;
  border-radius: 0 0 25px 25px;
  -webkit-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.4);
  transition: all ease-in-out 0.3s;
}
.servicios_shadow:hover {
  font-weight: bold;
  text-align: center;
  background-color: #ffffff;
  color: #363636;
  border-radius: 0 0 25px 25px;
  -webkit-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 15px 50px -15px rgba(0, 0, 0, 0.4);
  transition: all ease-in-out 0.3s;
}
.servicios_shadow:hover > img {
  transform: scale(1.1);
  transition: all ease-in-out 0.3s;
}

@media only screen and (min-width: 200px) and (max-width: 550px) {
  .brand-logo {
    height: 52px !important;
    margin-top: 5px;
  }

  /* redes */
  .redes {
    right: 20px;
    bottom: 40px;
  }
  /* fin - redes */

  /* contacto */
  .contenedor_contacto {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 50px;
  }

  .mapa_contacto {
    width: 100%;
    height: 300px;
  }
  /* fin - contacto */

  .imagen_servicios {
    width: 100%;
  }

  /* tarjetas servicios */
  .contenedor_servicios {
    justify-content: center;
    gap: 40px 20px;
  }

  .servicios_index_2 {
    width: 153px;
  }
  .servicios_index_2 div {
    padding: 20px 0px;
  }

  .servicios_index_2 a {
    font-weight: bold;
    text-align: center;
    color: #64bab7;
    text-decoration-line: underline;
  }
  /* fin - tarjetas servicios */

  .padding_1 {
    padding-bottom: 80px;
  }

  /* textos */
  body {
    font-size: 14px;
  }

  .text_1 {
    font-size: 14px;
  }
  .text_2 {
    font-size: 14px !important;
  }
  .text_3 {
    font-size: 12px;
  }
  .text_4 {
    font-size: 18px;
  }

  .titulo_1 {
    font-size: 22px;
    font-weight: bold;
    color: #363636;
  }
  .titulo_2 {
    color: #363636;
    font-size: 22px;
    font-weight: bold;
  }
  .titulo_3 {
    color: #363636;
    font-size: 18px;
    font-weight: bold;
  }

  .center_mobil {
    text-align: center;
  }

  .button_1 {
    font-size: 14px;
  }
  .button_2_1,
  .button_2 {
    font-size: 14px;
  }

  .button_3 {
    font-size: 14px;
  }

  .button_4 {
    font-size: 14px;
  }
  /* fin - textos */

  /* fondo */
  .contenedor_fondo {
    display: flex;
    margin-bottom: 50px;
  }
  .fondo_1 {
    position: absolute;
    z-index: -1;
    background: #b4d9f5;
    margin: 0 0 0 5%;
    width: 95%;
    height: 535px;
    border-radius: 50px 0 0 50px;
  }

  .fondo_2,
  .fondo_3 {
    background: #b4d9f5;
    width: 100%;
    padding: 50px 0;
  }
  /* fin - fondo */

  .imagen_index_1 {
    width: 100%;
    padding: 0 10%;
  }

  .mobil_off {
    display: none !important;
  }

  /* header */
  nav {
    height: 72px;
  }
  .logo_header {
    margin-top: 5px;
    height: 62px;
  }
  .nav-wrapper {
    height: 72px !important;
  }

  .hide-on-med-and-down {
    height: 72px;
  }

  .right {
    position: relative;
    left: 35px;
  }
  .burger {
    height: 72px;
  }
  /* fin - header */

  .center_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 25px;
  }
  .center_footer > div:first-child {
    margin-bottom: 10px;
  }

  .center_servicios_footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .center_servicios_footer > div {
    margin: auto;
    width: 200px !important;
  }
  .center_servicios_footer > div h5 {
    text-align: center;
  }

  .linea {
    display: flex;
    justify-content: center;
  }
  .linea::before {
    margin-left: 0px;
  }
  .linea_2 {
    display: flex;
    justify-content: center;
  }
  .linea_2::before {
    margin-left: 0px;
  }
  .linea_6 {
    display: flex;
    justify-content: center;
  }
  .linea_6::before {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 551px) and (max-width: 992px) {
  /* contacto */
  .contenedor_contacto {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 50px;
  }

  .mapa_contacto {
    width: 100%;
    height: 500px;
  }
  /* fin - contacto */

  .corte_tablet {
    height: 500px;
    overflow: hidden;
  }

  .imagen_servicios {
    width: 100%;
  }

  /* tarjetas servicios */
  .contenedor_servicios {
    justify-content: center;
  }

  .servicios_index_2 {
    width: 248px;
  }
  .servicios_index_2 div {
    padding: 20px 0px;
  }

  .servicios_index_2 a {
    font-weight: bold;
    text-align: center;
    color: #64bab7;
    text-decoration-line: underline;
  }
  /* fin - tarjetas servicios */

  .padding_1 {
    padding-bottom: 80px;
  }

  /* textos */
  .text_1 {
    font-size: 18px;
  }
  .text_2 {
    font-size: 16px !important;
  }
  .text_3 {
    font-size: 13px;
  }
  .text_4 {
    font-size: 20px;
  }

  .titulo_1 {
    font-size: 35px;
    font-weight: bold;
    color: #363636;
  }
  .titulo_2 {
    color: #363636;
    font-size: 22px;
    font-weight: bold;
  }
  .titulo_3 {
    color: #363636;
    font-size: 24px;
    font-weight: bold;
  }

  .center_mobil {
    text-align: center;
  }
  /* fin - textos */

  /* fondo */
  .contenedor_fondo {
    display: flex;
    margin-bottom: 75px;
  }
  .fondo_1 {
    position: absolute;
    z-index: -1;
    background: #b4d9f5;
    margin: 0 0 0 10%;
    width: 90%;
    height: 535px;
    border-radius: 50px 0 0 50px;
  }

  .fondo_2,
  .fondo_3 {
    background: #b4d9f5;
    width: 100%;
    padding: 50px 0;
  }
  /* fin - fondo */

  .imagen_index_1 {
    width: 100%;
    padding: 0 15%;
  }

  .mobil_off {
    display: none !important;
  }

  .center_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 25px;
  }
  .center_footer > div:first-child {
    margin-bottom: 10px;
  }

  .center_servicios_footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .center_servicios_footer > div {
    margin: auto;
    width: 200px !important;
  }
  .center_servicios_footer > div h5 {
    text-align: center;
  }

  .linea {
    display: flex;
    justify-content: center;
  }
  .linea::before {
    margin-left: 0px;
  }
  .linea_2 {
    display: flex;
    justify-content: center;
  }
  .linea_2::before {
    margin-left: 0px;
  }
  .linea_6 {
    display: flex;
    justify-content: center;
  }
  .linea_6::before {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1664px) {
  .imagen_servicios {
    width: 50%;
  }

  /* tarjetas servicios */
  .servicios_index_2 {
    width: 248px;
  }
  .servicios_index_2 div {
    padding: 20px 0px;
  }

  .servicios_index_2 a {
    font-weight: bold;
    text-align: center;
    color: #64bab7;
    text-decoration-line: underline;
  }
  /* fin - tarjetas servicios */

  /* textos */
  .text_1 {
    font-size: 18px;
  }
  .text_2 {
    font-size: 16px !important;
  }
  .text_3 {
    font-size: 13px;
  }
  .text_4 {
    font-size: 20px;
  }

  .titulo_1 {
    font-size: 35px;
    font-weight: bold;
    color: #363636;
  }
  .titulo_2 {
    color: #363636;
    font-size: 30px;
    font-weight: bold;
  }
  .titulo_3 {
    color: #363636;
    font-size: 24px;
    font-weight: bold;
  }
  /* fin - textos */

  /* fondo */
  .contenedor_fondo {
    display: flex;
    align-items: center;
    margin-bottom: 75px;
  }
  .fondo_1 {
    position: absolute;
    z-index: -1;
    background: #b4d9f5;
    margin: 0 5%;
    width: 90%;
    height: 535px;
    border-radius: 50px;
    margin-top: -190px;
  }

  .fondo_2 {
    background: #b4d9f5;
    margin: 0 5% 100px 5%;
    width: 90%;
    border-radius: 50px;
    padding: 50px 0;
  }

  .fondo_3 {
    background: #b4d9f5;
    margin: 0 15% 100px 15%;
    width: 70%;
    border-radius: 50px;
    padding: 50px 0;
  }
  /* fin - fondo */

  .imagen_index_1 {
    width: 100%;
    padding-right: 40px;
  }

  .logo_header {
    height: 102px;
  }

  .desktop_off {
    display: none !important;
  }

  .linea_6::before {
    margin-left: 28px;
  }
}

@media only screen and (min-width: 1665px) {
  .imagen_servicios {
    width: 50%;
  }

  /* tarjetas servicios */
  .servicios_index_2 {
    width: 248px;
  }
  .servicios_index_2 div {
    padding: 20px 0px;
  }

  .servicios_index_2 a {
    font-weight: bold;
    text-align: center;
    color: #64bab7;
    text-decoration-line: underline;
  }
  /* fin - tarjetas servicios */

  /* textos */
  .text_1 {
    font-size: 18px;
  }
  .text_2 {
    font-size: 16px !important;
  }
  .text_3 {
    font-size: 13px;
  }
  .text_4 {
    font-size: 20px;
  }

  .titulo_1 {
    font-size: 45px;
    font-weight: bold;
    color: #363636;
  }
  .titulo_2 {
    color: #363636;
    font-size: 30px;
    font-weight: bold;
  }
  .titulo_3 {
    color: #363636;
    font-size: 24px;
    font-weight: bold;
  }
  /* fin - textos */

  /* fondo */
  .contenedor_fondo {
    display: flex;
    align-items: center;
    margin-bottom: 75px;
  }
  .fondo_1 {
    position: absolute;
    z-index: -1;
    background: #b4d9f5;
    margin: 0 10%;
    width: 80%;
    height: 535px;
    border-radius: 50px;
    margin-top: -190px;
  }

  .fondo_2 {
    background: #b4d9f5;
    margin: 0 5% 110px 5%;
    padding: 50px 0;
    width: 90%;
    border-radius: 50px;
  }

  .fondo_3 {
    background: #b4d9f5;
    margin: 0 25% 100px 25%;
    width: 50%;
    border-radius: 50px;
    padding: 30px 0;
  }
  /* fin - fondo */

  /* containers */
  .contenedor_md {
    width: 55% !important;
  }
  /* fin - containers */

  .imagen_index_1 {
    width: 100%;
    padding-right: 60px;
  }

  .logo_header {
    height: 102px;
  }

  .desktop_off {
    display: none !important;
  }

  .linea_2::before {
    margin-left: 58px;
  }
  .linea_6::before {
    margin-left: 28px;
  }
}
