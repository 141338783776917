.choose-avatar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.avatar-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 580px;
    background-size: cover;
    border-radius: 55px;
    transition: transform 500ms;
    transition-timing-function: cubic-bezier(0.22, 0.11, 0.67, 0.55);
}

.avatars-container {
    display: flex;
    width: 100%;
    height: 800px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    font-family: monospace;
    position: relative;
    gap: 50px;
}

.avatars {
    position: relative;
    display: flex;
    gap: 50px;
    transition: transform 500ms;
}

.left_button {
    position: absolute;
    left: 10%;
    width: 50px;
    height: 50px;
    z-index: 2;
    cursor: pointer;
    background-color: #25E6C8;
    border-radius: 50%;
}
.left_button::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: '<';
    font-size: 35px;
    color: #fff;
}

.right_button {
    position: absolute;
    right: 10%;
    width: 50px;
    height: 50px;
    z-index: 2;
    cursor: pointer;
    background-color: #2BAAEC;
    border-radius: 50%;
}
.right_button::after {
    display: flex;
    justify-content: center;
    align-items: center;
    content: '>';
    font-size: 35px;
    color: #fff;
}

.current {
    transform: scale(1.3);
}

.current:hover {
    cursor: pointer;
}

@media only screen and (min-width:200px) and (max-width:550px){
    .avatars-container {
        height: 310px;
    }

    .avatar-item {
        width: 300px;
    }
}

@media only screen and (min-width:551px) and (max-width:992px){
    .avatars-container {
        height: 600px;
    }
}

@media only screen and (min-width:993px) and (max-width:1664px){
    .avatars-container {
        height: 600px;
    }
}

@media only screen and (min-width:1665px){
    .avatar-item {
        width: 700px;
    }
}