.wrapper {
    margin: 0 1rem;
    max-width: 600px;
    margin: auto;
    margin-right: -5%;
    padding-left: 10%;
}
@media only screen and (min-width:551px) and (max-width:992px){
    .wrapper {
        max-width: 800px;
        margin-right: -8%;
        padding-left: 15%;
    }
}

.horizontal-slider {
    width: 100%;
    height: 400px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    display: flex;
    align-items: center;
    position: relative;
    gap: 30px;
}

/* .active_card {
    scroll-snap-type: none;
} */

.horizontal-slider::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* make scrollbar transparent */
}

.card {
    margin-left: 0.5rem;
    height: 90%;
    scroll-snap-align: start;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

/* .hor-scroll .btn {
    position: absolute;

    top: 185px;
    transform: translateY(-50%);

    height: 30px;
    width: 30px;

    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    background-position: 50% 50%;
    background-repeat: no-repeat;

    z-index: 1;
} */