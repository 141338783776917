.header_calendario{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    background-color: #132640;
    color: #ffffff;
}

.flecha{
    font-size: 30px;
    font-weight: bold;
    width: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.izq{
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(180deg);
}
.izq::before{
    content: ">";
}
.dec{
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
}
.dec::after{
    content: ">";
}

.calendario_dias{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: #ffffff;
    color: #363636;
    border: solid 1px #D2D2D2 !important;
    border-bottom: none !important;
    border-right: none !important;
}
.dia{
    transition: all 0.3s ease-in-out;
    width: 14.28571428%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #81E293;
    cursor: pointer;
}
.dia:hover{
    transition: all 0.3s ease-in-out;
    background: #32b34a;
}
.disabled{
    transition: all 0.3s ease-in-out;
    width: 14.28571428%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    cursor: no-drop;
}
.disabled:hover::after{
    content: "Día inhábil";
    text-align: center;
    position: absolute;
    background-color: #dddddd;
    border-radius: 15px;
    padding: 5px 10px;
    width: 50px;
}

.dia_vacio{
    width: 14.28571428%;
    height: 60px;
    border-right: solid 1px #D2D2D2 !important;
    border-bottom: solid 1px #D2D2D2 !important;
}

.calendario_semanas{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F7F7F7;
    color: #363636;
    border:  solid 1px #D2D2D2 !important;
    border-bottom: none !important;
    cursor: default;
}
.dia_semana{
    width: 14.28571428%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid 2px #ffffff;
}
.dia_semana p{
    font-weight: bold;
    color: #92929D;
    margin: 0px;
}

.calendario_dia{
    border-right: solid 1px #D2D2D2;
    border-bottom: solid 1px #D2D2D2;
}
.calendario_lunes{
    border: solid 1px #D2D2D2;
    border-left: none !important;
    border-top: none;
}
.calendario_vacio{
    background: #ffffff;
}

.eventos > div {
    margin: 0px 35px;
    padding-bottom: 20px;
    border-bottom: solid 2px #E2E2EA;
}

.punto {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}


/* formulario */
.select_dia{
    padding: 0 !important;
    color: #363636 !important;
    font-weight: bold !important;
}
.select_dia::before{
    content: attr(title);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #363636;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}
[type="radio"]:disabled+div::before{
    color: #969696;
    cursor: no-drop;
}

[type="radio"]:checked+div:after{
    content: attr(title);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    margin: 0 !important;
    background-color: #132640 !important;
    border: #132640;
    height: 35px;
    width: 35px;
    -webkit-transition: .28s ease;
    transition: .28s ease;
    border-radius: 50%;
    transform: scale(1.02);
    cursor: pointer;
}

[type="radio"]:checked+div:before{
    display: none !important;
}

.hoy:before{
    content: attr(title);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    margin: 0 !important;
    background-color: #63636386 !important;
    border: #636363;
    height: 35px;
    width: 35px;
    -webkit-transition: .28s ease;
    transition: .28s ease;
    border-radius: 15px;
    transform: scale(1.02);
    cursor: pointer;
}
/* fin - formulario */


@media only screen and (min-width:200px) and (max-width:550px){ /*----------Móvil----------*/
    .calendario_movil_bajo{
        margin-bottom: 0px;
    }
    
    .calendario{
        width: 100%;
    }
    
    .eventos{
        width: 100%;
    }
}

@media only screen and (min-width:551px) and (max-width:850px){ /*----------Tablet----------*/
    .calendario{
        width: 100%;
    }
    
    .eventos{
        width: 100%;
    }
}

@media only screen and (min-width:851px) and  (max-width:1664px){ /*----------Pantalla chica----------*/
    .calendario{
        width: 100%;
    }
    
    .eventos{
        width: 40%;
    }
}

@media only screen and (min-width:1665px) { /*----------Pantalla grande----------*/
    .calendario{
        width: 100%;
    }
    
    .eventos{
        width: 40%;
    }
}