.servicios{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.servicios p{
    width: calc(100% - 36px);
}

.check{
    content: "";
    background-image: url(../../Images/iconos/1.svg);
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
    width: 26px;
    height: 20px;
    margin-right: 10px;
}

.divider_1{
    margin-top: 30px;
    margin-bottom: 30px;
}


.contenedor_flex_1, .contenedor_flex_2{
    display: flex;
    align-items: center;
    gap: 15px;
}
.contacto_img{
    background: #E2424E;
    width: 40px;
    height: 40px;
    padding: 7px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contacto_img img {
    height: 90%;
}

@media only screen and (min-width:200px) and (max-width:992px){
    .contenedor_flex_1{
        flex-direction: column;
        gap: 0px;
    }
    .contenedor_flex_2{
        justify-content: center;
        flex-direction: column;
        gap: 0px;
        margin-top: 10px;
    }

    .center_movil{
        text-align: center;
    }

    .grid_1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .grid_1 > div:nth-child(1){
        order: 2;
        margin-top: 20px;
    }
    .grid_1 > div:nth-child(1) img{
        width: 80% !important;
    }

    .grid_1 > div:nth-child(2){
        order: 1;
    }
    .grid_1 > div:nth-child(3){
        order: 3;
    }
}

@media only screen and (min-width:993px){
    .grid_1{
        display: grid;
    }
    
    .grid_1 > div:nth-child(1){
        grid-row: 1 / 2 sapn;
    }
    .grid_1 > div:nth-child(2){
        grid-column-start: 2;
        display: flex;
        align-items: flex-end;
    }
    .grid_1 > div:nth-child(3){
        grid-column-start: 2;
    }
}